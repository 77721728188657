import qs from "qs";
import {iListingQuery} from "../types/types";

export default function QueryParser(location: any, _default_query?: iListingQuery): iListingQuery {
    let default_query: iListingQuery = {
        sort_order: "id",
        sort_direction: "desc",
        per_page: 10, ..._default_query
    };
    let search = location.search.replace("?", "");
    let parsed = qs.parse(search)
    if (parsed.page) {
        let page = 1;
        try {
            page = parseInt(parsed.page.toString())
        } finally {
            default_query["page"] = page
        }
    }
    return default_query;
}
export const QueryParser1 = (location: any): any => {

    let search = location.search.replace("?", "");
    let parsed = qs.parse(search)
    if (parsed) {
        return parsed
    }
    return {};
}


export const getPageQuery = (page: number): string => {
    return qs.stringify({page: page});
}
