import {Box, Button, ListItem} from '@mui/material';
import {Link, useLocation} from "react-router-dom";

export const NavItem = (props: any) => {
    const {href, icon, title, ...others} = props;
    const location = useLocation();
    const active = href ? (location.pathname.includes(href)) : false;

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2
            }}
            {...others}
        >
            <Link to={href} style={{width: '100%',textDecoration:"none"}}>
                <Button
                    component="div"
                    startIcon={icon}
                    disableRipple
                    // @ts-ignore
                    sx={{
                        backgroundColor: !active ? '#ECDFF3' : '#603084',
                        borderRadius: 1,
                        color: active ? 'white' : '#603084',
                        fontWeight: active && 'fontWeightBold',
                        justifyContent: 'flex-start',
                        px: 3,
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color: active ? 'secondary.main' : '#9CA3AF'
                        },
                        '&:hover': {
                            backgroundColor: '#603084',
                            color: "white"
                        }
                    }}
                >
                    <Box sx={{flexGrow: 1}}>
                        {title}
                    </Box>
                </Button>
            </Link>

        </ListItem>
    );
};
