import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, Button, Container, InputAdornment, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import logo from "../../../assets/logo.png"

import * as yup from 'yup';
import {useFormik} from "formik";
import BaseAPIs from "../../../apis/base.apis";
import useIsMounted from "ismounted";
import {HandleErrors} from "../../../components/helper/form.helper";
import {AppStateContext} from "../../../App";

export function handleEqualAndGreaterZeroChange(e: any, handleChange: any) {
    if (!e.target.value) return handleChange(e);
    if (e.target.value === "0") {
        handleChange(e);
    }
    if (e.target.value.match(/[1-9]\d{0,5}/)) {
        if (e.target.value >= 0) {
            handleChange(e);
        }
    }

}

const Register = () => {

    const isMounted = useIsMounted();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const {loggedInUser} = useContext(AppStateContext)
    useEffect(() => {
        if (loggedInUser) {
            if (loggedInUser.admin) {
                window.location.href = "/admin"
                return
            }
            if (loggedInUser) {
                window.location.href = "/user"
            }
        }

    }, [])

    const [showRegistered, setShowRegistered] = useState(false);
    const formik = useFormik({
        initialValues: {
            user_name: "",
            user_email: "",
            user_designation: "",
            user_password: "",
            password_confirmation: "",
            user_mobile_number: "",
            organization_name: "",
            location_name: "",
            site_name: "",
        },
        validationSchema: yup.object({
            user_name: yup.string().required("Please enter name"),
            user_email: yup.string().email("Please enter valid email").required("Please enter email"),
            user_designation: yup.string().required("Please enter designation"),
            user_password: yup.string().min(8,"The password should be at least 8 characters long.").required("Please enter password"),
            password_confirmation: yup.string().required("Please confirm your password").min(8, 'The password should be at least 8 characters long.')
                .oneOf(
                    [yup.ref('user_password')],
                    'Password doesn\'t match',
                ),
            user_mobile_number: yup.string().min(10, "Please enter valid mobile number").max(10, "Please enter valid mobile number")
                .required("Please enter mobile number"),
            organization_name: yup.string().required("Please enter organization name"),
            location_name: yup.string().required("Please enter location name"),
            site_name: yup.string().required("Please enter site name"),

        }),
        onSubmit: (values, formikHelpers) => {
            setError("");
            new BaseAPIs().post("/_api/v1/auth/register-request", values).then(res => {
                if (isMounted) {
                    formikHelpers.setSubmitting(false);
                    if (BaseAPIs.hasError(res)) {
                        HandleErrors(res, formikHelpers);
                        setError(res.message);
                    } else {
                        setShowRegistered(true);
                    }
                }
            });
        }
    });
    return <>
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <Container maxWidth="sm">

                {
                    showRegistered && <Box>
                        <Box sx={{my: 3}} justifyContent={"center"} display={"flex"}>
                            <img alt={"Logo"} src={logo} style={{width: "200px"}}/>
                        </Box>
                        <Alert>
                        We have received your registration request. Our team will contact you within 2 business days.

                    </Alert>
                        <Box sx={{py: 2}}>
                            <Button
                                color="warning"
                                fullWidth
                                size="large"
                                type="button"
                                onClick={()=>{
                                    navigate("/login")
                                }}
                                variant="contained"
                            >
                                Go Back to Login
                            </Button>
                        </Box>
                    </Box>
                }
                {
                    !showRegistered && <form onSubmit={formik.handleSubmit}>
                        <Box sx={{my: 3}} justifyContent={"center"} display={"flex"}>
                            <img alt={"Logo"} src={logo} style={{width: "200px"}}/>
                        </Box>
                        <Box
                            sx={{
                                pb: 1,
                                pt: 2
                            }}
                        >
                            <Typography
                                align="center"
                                color="textSecondary"
                                variant="body1"
                            >
                                Register with Uniquo
                            </Typography>
                        </Box>

                        <TextField
                            label="Name *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.user_name}
                            name={"user_name"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.user_name && formik.errors.user_name)}
                            helperText={formik.touched.user_name && formik.errors.user_name}
                        />
                        <TextField
                            label="Email *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.user_email}
                            name={"user_email"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.user_email && formik.errors.user_email)}
                            helperText={formik.touched.user_email && formik.errors.user_email}
                        />
                        <TextField
                            label="Password *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.user_password}
                            name={"user_password"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.user_password && formik.errors.user_password)}
                            helperText={formik.touched.user_password && formik.errors.user_password}
                        />
                        <TextField
                            label="Confirm Password *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="password"
                            value={formik.values.password_confirmation}
                            name={"password_confirmation"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.password_confirmation && formik.errors.password_confirmation)}
                            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                        />
                        <TextField
                            label="Designation *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.user_designation}
                            name={"user_designation"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.user_designation && formik.errors.user_designation)}
                            helperText={formik.touched.user_designation && formik.errors.user_designation}
                        />
                        <TextField
                            label="Mobile Number"
                            id="outlined-start-adornment"

                            onBlur={formik.handleBlur}
                            onChange={(e => {
                                handleEqualAndGreaterZeroChange(e, formik.handleChange)
                            })}
                            type="text"
                            value={formik.values.user_mobile_number}
                            name={"user_mobile_number"}

                            sx={{mb: 2, width: '100%'}}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+91</InputAdornment>,

                            }}
                            inputProps={{
                                maxLength: 10
                            }}
                            error={Boolean(formik.touched.user_mobile_number && formik.errors.user_mobile_number)}
                            helperText={formik.touched.user_mobile_number && formik.errors.user_mobile_number}
                        />
                        <TextField
                            label="Organization name *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.organization_name}
                            name={"organization_name"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.organization_name && formik.errors.organization_name)}
                            helperText={formik.touched.organization_name && formik.errors.organization_name}
                        />
                        <TextField
                            label="City *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.location_name}
                            name={"location_name"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.location_name && formik.errors.location_name)}
                            helperText={formik.touched.location_name && formik.errors.location_name}
                        />
                        <TextField
                            label="Site *"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.site_name}
                            name={"site_name"}
                            sx={{mb: 2, width: '100%'}}
                            error={Boolean(formik.touched.site_name && formik.errors.site_name)}
                            helperText={formik.touched.site_name && formik.errors.site_name}
                        />
                        {
                            error && <Alert color={"error"} variant="standard" severity={"error"}>
                                {error}
                            </Alert>
                        }

                        <Box sx={{py: 2}}>
                            <Button
                                color="warning"
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Register
                            </Button>
                        </Box>

                    </form>
                }
            </Container>

        </Box>
    </>
}
export default Register;
