import {CustomLoader} from "../../components/Spinner/CustomLoader";
import {Navigate, Route, Routes} from "react-router-dom";
import React, {lazy, Suspense, useContext} from "react";
import {DashboardLayout} from "../../components/Layout/DashboardLayout";
import {ChartBar as ChartBarIcon} from "../../icons/chart-bar";
import SearchIcon from '@mui/icons-material/Search';
import BusinessIcon from '@mui/icons-material/Business';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {AppStateContext} from "../../App";

import DesignServicesIcon from '@mui/icons-material/DesignServices';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AssessmentIcon from "@mui/icons-material/Assessment";

const OrganizationsRoutes = lazy(() => import('./Organizations/Organizations.Routes'));
const InventoriesAsContractors = lazy(() => import('./Organizations/InventoriesAsContractors'));
const EngineerRoute = lazy(() => import('./Engineer/Engineer.Routes'));
const AuditorRoutes = lazy(() => import('./Auditor/Auditor.Routes'));
const DecommissionRequestsRoutes = lazy(() => import('./DecommissionRequests/DecommissionRequests.Routes'));
const UserDashboard = lazy(() => import('./Dashboard/User.Dashboard'));
const SearchPage = lazy(() => import('../Common/Search/Search.Page'));
const UserReports = lazy(() => import('../Users/Reports/User.Reports'));

const ContactUs = lazy(() => import('../Common/ContactUS/ContactUS'));
export default function UserRoutes() {
    const {quick_access, loggedInUser} = useContext(AppStateContext);
    const items = [
        {
            href: '/user/dashboard',
            icon: (<ChartBarIcon fontSize="small"/>),
            title: 'Dashboard'
        },
        {
            href: '/user/search',
            icon: (<SearchIcon fontSize="small"/>),
            title: 'Search by ID or QR'
        },
        {
            href: '/user/organizations',
            icon: (<BusinessIcon fontSize="small"/>),
            title: 'Organizations'
        },
        ...(quick_access && quick_access.organization && !quick_access.location) ?
            [{
                href: `/user/organizations/${quick_access.organization.uid}/locations`,
                icon: (<LocationSearchingIcon fontSize="small"/>),
                title: 'Locations'
            }] : [],
        ...(quick_access && quick_access.organization && quick_access.location && !quick_access.site) ?
            [{
                href: `/user/organizations/${quick_access.organization.uid}/locations/${quick_access.location.uid}/sites`,
                icon: (<CorporateFareIcon fontSize="small"/>),
                title: 'Sites'
            }] : [],
        ...(quick_access && quick_access.organization && quick_access.location && quick_access.site) ?
            [{
                href: `/user/organizations/${quick_access.organization.uid}/locations/${quick_access.location.uid}/sites/${quick_access.site.uid}/inventories`,
                icon: (<PrecisionManufacturingIcon fontSize="small"/>),
                title: 'Inventories'
            }] : [],
        ...(loggedInUser && (loggedInUser.is_engineer)) ?
            [{
                href: `/user/engineer/inventories/`,
                icon: (<PrecisionManufacturingIcon fontSize="small"/>),
                title: 'Inventories'
            }] : [],
        ...(loggedInUser && (loggedInUser.is_auditor)) ?
            [{
                href: `/user/auditor/inventories/`,
                icon: (<PrecisionManufacturingIcon fontSize="small"/>),
                title: 'Inventories'
            }] : [],
        ...(loggedInUser && loggedInUser.has_decommission_rights) ?
            [{
                href: '/user/decommission-requests',
                icon: (<FolderDeleteIcon fontSize="small"/>),
                title: 'Decommission Requests'
            }] : [],
        ...(loggedInUser && !loggedInUser.is_engineer && !loggedInUser.is_auditor) ?
            [{
                href: '/user/tpi-requests',
                icon: (<DesignServicesIcon fontSize="small"/>),
                title: 'Request TPI'
            },] : [],

        ...(loggedInUser && loggedInUser.is_engineer) ?
            [
                {

                icon: (<DesignServicesIcon fontSize="small"/>),
                title: 'TPI',
                sub_menus:[
                    {
                        href: '/user/engineer/call-generate',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'Call Generate'
                    },
                    {
                        href: '/user/engineer/tpi-requests',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI Requests'
                    },
                    {
                        href: '/user/engineer/tpi-active',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI in Progress'
                    },
                    {
                        href: '/user/engineer/tpi-history',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI History'
                    },
                ]
            },
            ] : [],
        ...(loggedInUser && loggedInUser.is_auditor) ?
            [
                {

                icon: (<DesignServicesIcon fontSize="small"/>),
                title: 'TPI',
                sub_menus:[
                    {
                        href: '/user/auditor/tpi-active',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI in Progress'
                    },
                    {
                        href: '/user/auditor/tpi-history',
                        icon: (<DesignServicesIcon fontSize="small"/>),
                        title: 'TPI History'
                    },
                ]
            },
            ] : [],

        {
            href: '/user/reports',
            icon: (<AssessmentIcon fontSize="small"/>),
            title: 'Reports'
        },
        {
            href: '/user/contact-us',
            icon: (<AlternateEmailIcon fontSize="small"/>),
            title: 'Contact us'
        },

    ];
    return <DashboardLayout menu={items}>
        <Suspense fallback={<CustomLoader/>}>
            <Routes>
                <Route path="/dashboard" element={<UserDashboard/>}/>
                <Route path="/search" element={<SearchPage/>}/>
                <Route path="/organizations/*" element={<OrganizationsRoutes/>}/>
                <Route path="/engineer/*" element={<EngineerRoute/>}/>
                <Route path="/auditor/*" element={<AuditorRoutes/>}/>
                <Route path="/tpi-requests/*" element={<OrganizationsRoutes/>}/>
                <Route path="/reports/*" element={<UserReports/>}/>
                <Route path="/contact-us/*" element={<ContactUs/>}/>
                <Route path="/decommission-requests/*" element={<DecommissionRequestsRoutes/>}/>
                <Route path="*" element={<Navigate to="dashboard"/>}/>
            </Routes>
        </Suspense>
    </DashboardLayout>
}
