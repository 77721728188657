import React, {useContext, useEffect, useState} from "react";
import {Alert, Box, Button, Container, InputAdornment, TextField, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import logo from "../../../assets/logo.png"

import * as Yup from 'yup';
import {useFormik} from "formik";
import BaseAPIs from "../../../apis/base.apis";
import useIsMounted from "ismounted";
import {HandleErrors} from "../../../components/helper/form.helper";
import {AppStateContext} from "../../../App";

export function handleEqualAndGreaterZeroChange(e: any, handleChange: any) {
    if (!e.target.value) return handleChange(e);
    if (e.target.value === "0") {
        handleChange(e);
    }
    if (e.target.value.match(/[1-9]\d{0,5}/)) {
        if (e.target.value >= 0) {
            handleChange(e);
        }
    }

}
const Login = () => {

    const isMounted = useIsMounted();
    const [error, setError] = useState("");
    const navigate=useNavigate();
    const {loggedInUser} = useContext(AppStateContext)
    useEffect(() => {
        if (loggedInUser) {
            if (loggedInUser.admin) {
                window.location.href = "/admin"
                return
            }
            if (loggedInUser) {
                window.location.href = "/user"
            }
        }

    }, [])
    const [params] = useSearchParams();
    const formik = useFormik({
        initialValues: {
            mobile_number: '',
            password: '',
            device_type: "web"
        },
        validationSchema: Yup.object({
            mobile_number: Yup
                .string()
                .required('Mobile number is required'),
            password: Yup
                .string()
                .max(255)
                .required('Password is required'),
            device_type: Yup
                .string()
        }),
        onSubmit: (values, formikHelpers) => {
            new BaseAPIs().post("/_api/v1/auth/login", values).then(res => {
                if (isMounted) {
                    formikHelpers.setSubmitting(false);
                    if (BaseAPIs.hasError(res)) {
                        HandleErrors(res, formikHelpers);
                        setError(res.message)
                    } else {
                        if (res.user) {
                            if (res.user.admin) {
                                window.location.href = "/admin"
                                return;
                            } else {
                                window.location.href = "/user"
                            }
                        }
                    }
                }
            });
        }
    });
    return <>
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <Container maxWidth="sm">
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{my: 3}} justifyContent={"center"} display={"flex"}>
                        <img alt={"logo"} src={logo} style={{width: "200px"}}/>
                    </Box>
                    <Box
                        sx={{
                            pb: 1,
                            pt: 2
                        }}
                    >
                        <Typography
                            align="center"
                            color="textSecondary"
                            variant="body1"
                        >
                            Please sign in to continue
                        </Typography>
                    </Box>

                    <TextField
                        label="Mobile Number"
                        id="outlined-start-adornment"

                        onBlur={formik.handleBlur}
                        onChange={(e => {
                            handleEqualAndGreaterZeroChange(e, formik.handleChange)
                        })}
                        type="text"
                        value={formik.values.mobile_number}
                        name={"mobile_number"}

                        sx={{ mb: 2, width: '100%' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,

                        }}
                        error={Boolean(formik.touched.mobile_number && formik.errors.mobile_number)}
                        helperText={formik.touched.mobile_number && formik.errors.mobile_number}
                        inputProps={{
                            maxLength: 10
                        }}
                    />


                    <TextField
                        error={Boolean(formik.touched.password && formik.errors.password)}
                        fullWidth
                        helperText={formik.touched.password && formik.errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="password"
                        value={formik.values.password}
                        variant="outlined"

                    />
                    {
                        error && <Alert color={"error"} variant="standard" severity={"error"}>
                            {error}
                        </Alert>
                    }
                    <Box sx={{py: 2}}>
                        <Button
                            color="primary"
                            disabled={formik.isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Login
                        </Button>
                    </Box>
                    <Box sx={{py: 2}}>
                        <Button
                            color="warning"
                            fullWidth
                            size="large"
                            type="button"
                            onClick={()=>{
                                navigate("/register")
                            }}
                            variant="contained"
                        >
                            Register
                        </Button>
                    </Box>

                </form>
            </Container>
        </Box>
    </>
}
export default Login;
