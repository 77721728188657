import React, {createContext, Dispatch, Suspense, useCallback, useContext, useEffect, useState} from "react";
import {iStoreAction} from "../reducer";
import {AppDispatchContext, AppStateContext} from "../App";
import BaseAPIs from "../apis/base.apis";
import useIsMounted from "ismounted";
import {AlertColor} from "@mui/material/Alert/Alert";
import {CustomLoader} from "../components/Spinner/CustomLoader";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Login from "./Login/Login/Login";
import AdminRoutes from "./Admin/AdminRoutes";
import {QueryParser1} from "../components/QueryParser";
import {Alert, Snackbar} from "@mui/material";
import UserRoutes from "./Users/UserRoutes";
import Register from "./Login/Register/Register";
import PrivacyPolicy from "./PrivacyPolicy";

export const SnackbarContext = createContext<{
    showSuccessSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void,
    showErrorSnackbarMessage: (args: {
        show?: boolean,
        severity?: AlertColor,
        message?: string
    }) => void
}>({
    showErrorSnackbarMessage: args => {
    },
    showSuccessSnackbarMessage: args => {
    },
});

export default function AppRouter() {


    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);

    const [loading, setLoading] = useState(true);

    const isMounted = useIsMounted();
    const onFetchUser = useCallback(() => {
        setLoading(true);
        new BaseAPIs().get("/_api/v1/get-user")
            .then((response) => {
                if (isMounted.current) {
                    if (BaseAPIs.hasError(response)) {
                        setLoading(false);
                    } else {
                        dispatch({
                            type: "set_logged_in_user",
                            loggedInUser: response.user,
                            quick_access: response.quick_access
                        })
                        setLoading(false);

                    }
                }
            });
    }, [isMounted]);

    useEffect(() => {
        onFetchUser();
    }, [])

    useEffect(() => {
        const handleInvalidToken = (e: any) => {
            if (e.key === 'loggedInUser_uniquo' && e.oldValue && !e.newValue) {
                window.location.reload();
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    const [snackBarState, setSnackBarState] = useState<{
        show: boolean,
        severity: AlertColor,
        message: string
    }>({
        show: false,
        severity: "success",
        message: "Hi"
    });
    if (loading) {
        return <CustomLoader/>;
    }
    return <>
        <SnackbarContext.Provider value={{
            showErrorSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "warning", ...args}
                });
            }),
            showSuccessSnackbarMessage: (args => {
                setSnackBarState(prevState => {
                    return {...prevState, show: true, severity: "success", ...args}
                });
            }),
        }}>
            <Suspense fallback={<CustomLoader/>}>
                <Routes>
                    <Route path="/admin/*"
                           element={<RequireAuth admin redirect={"/login"}><AdminRoutes/></RequireAuth>}/>
                    <Route path="/user/*"
                           element={<RequireAuth user no_admin redirect={"/login"}><UserRoutes/></RequireAuth>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<RequireAuth admin redirect={"/login"}><AdminRoutes/></RequireAuth>}/>
                </Routes>
            </Suspense>
        </SnackbarContext.Provider>
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={snackBarState.show} autoHideDuration={6000} onClose={() => {
            setSnackBarState(prevState => {
                return {...prevState, show: false}
            });
        }}>
            <Alert onClose={() => {
                setSnackBarState(prevState => {
                    return {...prevState, show: false}
                });
            }} severity={snackBarState.severity} sx={{width: '100%'}}>
                {snackBarState.message}
            </Alert>
        </Snackbar>
    </>
}
export const RequireAuth = ({
                                admin,
                                no_admin,

                                user,
                                noAuth,
                                redirect,
                                children
                            }: {
    admin?: boolean,
    no_admin?: boolean,
    user?: boolean,
    noAuth?: boolean,
    redirect?: string,
    children: any,

}) => {
    const {loggedInUser} = useContext(AppStateContext)

    const location = useLocation()
    if (user) {
        if (loggedInUser) {
            return children;
        }
    }
    if (no_admin) {
        if (loggedInUser && !loggedInUser.admin) {
            return children;
        }
    }
    if (admin) {
        if (loggedInUser && loggedInUser.admin) {
            return children;
        }
    }
    if (noAuth) {
        if (!loggedInUser) {
            return children;
        }
    }
    let query = QueryParser1(location)
    if (query && query.go) {
        return <Navigate replace to={"/" + query.go}/>
    }
    return <Navigate replace to={redirect || "/"}/>
}
