import {Box, Divider, Drawer, useMediaQuery} from '@mui/material';
import {NavItem} from './nav-item';
import logo from "../assets/logo.png"
import {NavItemMulti} from "./nav-item-multi";


export const DashboardSidebar = (props: any) => {
    const {open, onClose, items} = props;
    const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <div>
                    <Box sx={{p: 1}}>
                        <a href="/">
                            <img alt={"Logo"} src={logo}
                                 style={{width: "200px"}}
                            />
                        </a>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 1
                    }}
                />
                <Box sx={{flexGrow: 1}}>
                    {items.map((item: any) => {
                        if(!item.sub_menus){
                            return <NavItem
                                key={item.title}
                                icon={item.icon}
                                href={item.href}
                                title={item.title}
                            />
                        }else {
                            return <NavItemMulti
                                key={item.title}
                                icon={item.icon}
                                href={item.href}
                                title={item.title}
                                submenus={item.sub_menus}
                            />
                        }

                        }
                    )}


                </Box>
                <Divider sx={{borderColor: '#2D3748'}}/>
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: '#ECDFF3',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: '#9250BC',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{zIndex: (theme) => theme.zIndex.appBar + 100}}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};
