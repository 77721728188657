import React from 'react';
import {Container} from "@mui/material";

function PrivacyPolicy() {
    return (
        <Container>
            <h1>Privacy Policy</h1>

            <p><strong>Effective Date: 12 Oct 2023</strong></p>

            <h2>1. Introduction</h2>
            <p>Welcome to Uniquo, your trusted source for the inspection and certification of lifting tools and tackles. This Privacy Policy explains how we collect, use, and protect your personal information. By using our services, you agree to the terms of this Privacy Policy.</p>

            <h2>2. Information We Collect</h2>
            <p>We collect the following personal information from our users through the use of forms:</p>
            <ul>
                <li>Name</li>
                <li>Email</li>
                <li>Phone Number</li>
            </ul>

            <h2>3. How We Collect Information</h2>
            <p>We collect your personal information through forms on our website when you provide it voluntarily for the purpose of communication.</p>

            <h2>4. Why We Collect Information</h2>
            <p>We collect this information for the sole purpose of communication with our users.</p>

            <h2>5. Storage and Protection</h2>
            <p>We take reasonable measures to ensure the security and confidentiality of your personal information. We do not share this information with any third parties.</p>

            <h2>6. Use of Cookies</h2>
            <p>We use cookies for user sessions on our website. Cookies help us improve your experience and provide personalized content.</p>

            <h2>7. Sharing Information</h2>
            <p>We do not share your personal information with any third parties.</p>

            <h2>8. Access and Deletion of Information</h2>
            <p>You can contact Uniquo customer care to request access to, modification of, or deletion of your personal information. We will respond to such requests within a reasonable time frame.</p>

            <h2>9. Compliance with Data Protection Regulations</h2>
            <p>We do not share your personal information with third parties and, therefore, do not require specific data protection compliance.</p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions or concerns regarding your privacy or this Privacy Policy, please contact us through our website's "Contact Us" section.</p>

            <h2>11. Age Restriction</h2>
            <p>Our services are not intended for individuals under the age of 18.</p>

            <h2>12. Additional Policies</h2>
            <p>This Privacy Policy is the sole policy governing your use of Uniquo's website. There are no additional terms or policies you need to be aware of.</p>

            <h2>13. Changes to the Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be reflected on this page, and the effective date will be updated.</p>

            <p>By using Uniquo's website, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not use our website.</p>

        </Container>
    );
}

export default PrivacyPolicy;
