import PropTypes from 'prop-types';
import {Box, MenuItem, MenuList, Popover, Typography} from '@mui/material';
import {Dispatch, useContext, useState} from "react";
import {AppDispatchContext, AppStateContext} from "../App";
import BaseAPIs from "../apis/base.apis";
import {iStoreAction} from "../reducer";
import useIsMounted from 'ismounted';
import UserChangePasswordDialog from "./dialogs/UserChangePasswordDialog";

export const AccountPopover = (props: any) => {
    const {anchorEl, onClose, open, ...other} = props;
    const {loggedInUser} = useContext(AppStateContext)
    const [changePassword,setChangePassword]=useState(false)
    const isMounted = useIsMounted();
    const dispatch: Dispatch<iStoreAction> = useContext(AppDispatchContext);
    const handleSignOut = async () => {
        onClose?.();
        new BaseAPIs().post("/_api/v1/auth/logout").then(value => {
            if (isMounted.current) {
                dispatch({type: "logout"})
            }
        })


    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {width: '300px'}
            }}
            {...other}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Typography variant="overline">
                    Account
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {loggedInUser && loggedInUser.name}
                </Typography>
            </Box>
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                        '&:first-of-type': {
                            borderTopColor: 'divider',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px'
                        },
                        padding: '12px 16px'
                    }
                }}
            >
                <MenuItem onClick={()=>{
                    setChangePassword(true)
                }}>
                    Change Password
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    Sign out
                </MenuItem>
            </MenuList>
            <UserChangePasswordDialog show={changePassword} onCancel={()=>{
                setChangePassword(false)
            }} onConfirm={()=>{
                setChangePassword(false)
            }}/>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired
};
