import React, {useContext, useState} from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {HandleErrors} from "../helper/form.helper";
import BaseAPIs from "../../apis/base.apis";
import {SnackbarContext} from "../../pages/AppRouter";

export default function UserChangePasswordDialog(props: { show?: boolean, onConfirm: any, onCancel: any }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // @ts-ignore

    const [_error, setError] = useState("");

    const {showSuccessSnackbarMessage} = useContext(SnackbarContext);
    return <Dialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        fullWidth
        open={!!props.show}
        onClose={props.onCancel}
        aria-labelledby="responsive-dialog-title"
    >
        <Formik
            initialValues={{
                current_password: "",
                password: "",
                password_confirmation: "",
            }}
            validationSchema={Yup.object({
                password: Yup.string().required("Please enter password")
                    .min(8, ('The password should be at-least of 8 characters.')),
                password_confirmation: Yup
                    .string()
                    .max(255)
                    .required('Password is required').oneOf(
                        [Yup.ref('password')],
                        'Password doesn\'t match',
                    ),
                current_password: Yup
                    .string()
                    .required('Please enter current password'),
            })}
            onSubmit={(values, formikHelpers) => {
                new BaseAPIs().post(`/_api/v1/user/change-password`, values).then(res => {
                    formikHelpers.setSubmitting(false);
                    if (BaseAPIs.hasError(res, showSuccessSnackbarMessage)) {
                        HandleErrors(res, formikHelpers);
                        setError(res.message)
                    } else {
                        showSuccessSnackbarMessage({message: "Password has been changed successfully"})
                        props.onConfirm()
                    }
                })

            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  isSubmitting,
                  validateForm,
                  errors,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
              }) => {
                return <form onSubmit={handleSubmit}>
                    <DialogTitle id="responsive-dialog-title">
                        Change Password
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            error={Boolean(touched.current_password && errors.current_password)}
                            fullWidth
                            helperText={touched.current_password && errors.current_password}
                            label="Current Password"
                            margin="normal"
                            name="current_password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.current_password}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                            fullWidth
                            helperText={touched.password_confirmation && errors.password_confirmation}
                            label="Confirm Password"
                            margin="normal"
                            name="password_confirmation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password_confirmation}
                            variant="outlined"
                        />

                        {
                            _error && <Alert color={"error"} variant="standard" severity={"error"}>
                                {_error}
                            </Alert>
                        }
                        <DialogActions>
                            <Button autoFocus onClick={async () => {
                                props.onCancel()
                            }}>
                                Cancel
                            </Button>
                            <Button type="submit">
                                Confirm
                            </Button>

                        </DialogActions>
                    </DialogContent>
                </form>
            }}
        </Formik>


    </Dialog>
}
