import {FormikHelpers} from "formik";

export const getFlatKeys = (o: any, prefix = ''): string[] => {
    return Object.keys(o).flatMap(k => {
        const dot = prefix ? '.' : '';
        const new_k = isNaN(+k) ? `${dot}${k}` : `[${k}]`;
        const new_prefix = `${prefix}${new_k}`;
        return Object(o[k]) === o[k] ? getFlatKeys(o[k], new_prefix) : [new_prefix]
    });
}

export function HandleErrors(res: any, helpers: FormikHelpers<any>, form_id?: string) {

    if (res.errors) {
        let keys = Object.keys(res.errors)
        for (let i = 0; i < keys.length; i++) {
            helpers.setFieldError(keys[i], res.errors[keys[i]][0])
        }
        if (keys.length) {
            return focusInputElementByName(keys[0], form_id);
        }
        return true
    }
    return false;
}

export function getOneErrorField(res: any,) {

    if (res.errors) {
        let keys = Object.keys(res.errors)
        if (keys.length > 0) {
            return keys[0]
        }
    }
    return "";
}

export function HandleErrorsWithFieldError(res: any, setFieldError: any, form_id?: string) {

    if (res.errors) {
        let keys = Object.keys(res.errors)
        for (let i = 0; i < keys.length; i++) {
            setFieldError(keys[i], res.errors[keys[i]][0])
        }
        if (keys.length) {
            return focusInputElementByName(keys[0], form_id);
        }
        return true
    }
    return false;
}

export function focusOnError(errors: any, form_id?: string) {
    const keys = getFlatKeys(errors);
    if (keys.length) {
        return focusInputElementByName(keys[0], form_id);
    }
}

export function focusInputElementByName(name: string, form_id?: string): boolean {

    if (document.activeElement)
        (document.activeElement as HTMLFormElement).blur();
    if (form_id) {
        const form = document.getElementById(form_id);
        if (form) {
            const ele = form.querySelector<HTMLInputElement>(`[name=${CSS.escape(name)}]`)
            if (ele) {
                ele.focus();
                if (ele.parentElement && (ele.type === "hidden" || ele.hidden)) {
                    const offset = 45;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = ele.parentElement.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
                return true
            }
        }
    }
    const els = document.getElementsByName(name);
    if (els && els.length > 0) {
        const selected_element = els[0] as HTMLInputElement;
        selected_element.focus();
        if (selected_element.parentElement && selected_element.type === "hidden") {
            const offset = 45;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = selected_element.parentElement.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }

        return true
    }
    return false
}

export function handleContactNumberChange(e: any, handleChange: any) {
    let phone_no = /^[\ \d\-\+\(\)]*$/;
    if (e.target.value.match(phone_no)) {
        handleChange(e);
    }
}
