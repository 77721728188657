import {iQuickAccess, iStoreState, iUser} from "./types/types";


export const getInitialStoreState = (): iStoreState => {
    return {
        loggedInUser: undefined,
        statusCode: 200,
        quick_access: undefined
    }
};
export type iStoreAction =
    | { type: 'verifying_user' }
    | { type: 'failed_to_user' }
    | { type: 'set_logged_in_user', loggedInUser: iUser | undefined, quick_access: iQuickAccess | undefined }
    | { type: 'logout', }


export function appReducer(state: iStoreState, action: iStoreAction): iStoreState {
    switch (action.type) {
        case 'set_logged_in_user':
            window.localStorage.setItem('loggedInUser_uniquo', (action.loggedInUser && action.loggedInUser?.email) || "");
            return {...state, loggedInUser: action.loggedInUser, quick_access: action.quick_access};

        case 'logout':
            window.localStorage.removeItem('loggedInUser_uniquo');
            return {...state, loggedInUser: undefined,quick_access:undefined};

        default:
            return {...state};
    }
}


