import {Box, Button, Collapse, ListItem} from '@mui/material';
import {useLocation} from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {NavItem} from "./nav-item";
import {useState} from "react";

export const NavItemMulti = (props: any) => {
    const { icon, title, submenus, ...others} = props;
    const location = useLocation();
    const [showChild,setShowChild]=useState(false);
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2
            }}
            {...others}
        >
            <div  style={{width: '100%', textDecoration: "none",cursor:"pointer"}}>
                <Button
                    component="div"
                    startIcon={icon}
                    disableRipple
                    onClick={()=>{

                        setShowChild(!showChild)
                    }}
                    // @ts-ignore
                    sx={{
                        backgroundColor: '#ECDFF3',
                        borderRadius: 1,
                        color: '#603084',
                        justifyContent: 'flex-start',
                        px: 3,
                        display: "flex",
                        alignItems: "center",
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        '& .MuiButton-startIcon': {
                            color:'#9CA3AF'
                        },
                        '&:hover': {
                            backgroundColor: '#603084',
                            color: "white"
                        }
                    }}
                >
                    <Box sx={{flexGrow: 1}}>
                        {title}
                    </Box>
                    <Box>
                        <ExpandMoreIcon sx={{rotate:showChild?"180deg":"0deg"}}/>
                    </Box>
                </Button>
                <Collapse orientation="vertical" in={showChild} >
                    <Box sx={{marginTop:0.5}} >
                        <ul style={{listStyle:"none",margin:0,padding:0}}>
                            {
                                submenus && submenus.map((item:any) => (
                                    <NavItem
                                        key={item.title}
                                        icon={item.icon}
                                        href={item.href}
                                        title={item.title}
                                    />
                                ))
                            }
                        </ul>
                    </Box>
                </Collapse>


            </div>

        </ListItem>
    );
};
